@font-face {
    font-family: "Morelife Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Morelife Regular"), url("Morelife-JR0LM.woff") format("woff");
}

* {
    /* box-sizing: border-box;
    margin: 0; */
}

#hero {
    display: flex;
    justify-content: center;
    align-items: center;
}

canvas {
    position: sticky;
    width: 100%;
    height: 100vh;
}
img {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
}

/* a {
    position: absolute;
    bottom: 2vmin;
    right: 2vmin;
    color: rgba(255, 255, 255, 0.2);
    text-decoration: none;
} */

a:hover {
    color: #fff;
}

/*  ======  HEADER  ===
======== */
/* #header {
    border: solid 1px white;
    z-index: 5;
} */
/*/================ NAME ====================== */

h1 {
    font-family: "Morelife Regular";
    font-style: normal;
    font-weight: 400;
    font-style: normal;
    font-weight: normal;
    src: local("Morelife Regular"), url("Morelife-JR0LM.woff") format("woff");
    font-size: 5vmin; /* responsive font size */
    /* font-size: 50px; */
    font-size: calc(20px + 3.8vmin);
    text-align: center;
}

h2 {
    font-family: "Morelife Regular";
    font-style: normal;
    font-weight: 400;
    font-style: normal;
    font-weight: normal;
    src: local("Morelife Regular"), url("Morelife-JR0LM.woff") format("woff");
    font-size: 4vmin; /* responsive font size */
    /* font-size: 50px; */
    font-size: calc(20px + 3.8vmin);
    text-align: center;
}
h3 {
    /* font-size: 1.8vmin; responsive font size */
    font-size: calc(10px + 0.8vmin);
    text-align: center;
    font-size: 2.6vmin; /* responsive font size */
}
.infoName {
    position: absolute;
    top: 5%;
    left: 3%;
    z-index: 1;
    background-color: transparent;
}

.projectName {
    font-family: "Morelife Regular";
    font-style: normal;
    font-weight: 400;
    font-style: normal;
    font-weight: normal;
    src: local("Morelife Regular"), url("Morelife-JR0LM.woff") format("woff");
    font-size: 4vmin; /* responsive font size */
    /* font-size: 50px; */
    font-size: calc(20px + 3.8vmin);
    text-align: center;
}

/* SOCIALS Icon *****************************************/

.socialIcon:hover {
    transition: 0.5s;
    transform: rotate(-20deg);
    -webkit-transform: rotate(5deg);
    filter: drop-shadow(0 0 3px #ffffff);
    cursor: pointer;
    width: 200px;
}

#githubIcon {
    position: absolute;
    z-index: 1;
    transition: 0.5s;
    width: 34px;
    right: 80px;
    top: 5.4%;
}
#linkedinIcon {
    position: absolute;
    z-index: 1;
    transition: 0.5s;
    width: 42px;
    right: 30px;
    top: 5%;
}

#githubIcon:hover #linkedinIcon:hover {
    transform: rotate(-20deg);
    -webkit-transform: rotate(-10deg);
}
