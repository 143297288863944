body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    box-sizing: border-box;
    color: white;
    margin: 0;
    font-family: "Montserrat", sans-serif;

    /* background-color: #0d0f33; */

    /* color: #5daf4e; */
}
.mainPage {
    /* width: 100vw; */
}

.pageWrapper {
}

.pageSection {
    margin: 0%;
    /* margin-top: 20px; */
    /* background-color: #0d0f33; */
    background-color: #000;
    /* background-image: radial-gradient(
            circle at top right,
            rgba(121, 68, 154, 0.13),
            transparent
        ),
        radial-gradient(
            circle at 20% 80%,
            rgba(41, 196, 255, 0.13),
            transparent
        ); */
}

.contactWrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 4;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    padding: -10%;
    border: rgba(255, 255, 255, 0.763) solid 1px;
    border-radius: 20px;
    overflow: scroll;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    padding: 5%;
    box-shadow: -2px 1px 24px 2px rgba(255, 255, 255, 0.25);
}

.contactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5%;
    width: 90%;
    grid-gap: 10px;
    gap: 10px;
}

.contactForm input {
    width: 80%;
    border-radius: 5px;
    color: white;
    padding: 10px;
    background: none;
}

.contactForm textarea {
    width: 80%;
    height: 150px;
    color: white;
    border-radius: 5px;
    padding: 10px;
    background: none;
}

#sendButton {
    width: 60px;
    height: 60px;
    background-color: none;
    border-radius: 50%;
    /* background-color: white; */
    color: white;
    font-weight: bolder;
    font-size: 10px;
}

#sendButton:hover {
    cursor: pointer;
}

#closeProjects {
    position: absolute;
    z-index: 35px;
    width: 35px;
    top: 12px;
    right: 15px;
    cursor: pointer;
    z-index: 5;
    /* border: solid white 1px; */

    /* background-color: white; */
    /* border: #ffffff solid 1px; */
    /* border-radius: 50%; */
}
#closeProjects:hover {
    filter: drop-shadow(0 0 2px #ffffff);
}

.githubProjectWrapper {
    position: absolute;
    z-index: 5;
    top: 12px;
    right: 55px;
    height: 32px;
    width: 32px;
    cursor: pointer;
    /* border: solid white 1px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.githubProjectWrapper img {
    max-width: 100%;
    max-height: 100%;
    /* position: absolute;
    top: 22px;
    left: 18px;
    height: 35px;
    width: 35px;
    padding: 2px;
    border: solid white 1px; */
}

.githubProjectWrapper:hover {
    filter: drop-shadow(0 0 2px #ffffff);
}
.githubProjectWrapper a {
}

/* ========================================== */
.projectsWrapper {
    position: absolute;
    z-index: 4;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 1000px;
    max-height: 90vh;
    padding: -10%;
    border: rgba(255, 255, 255, 0.763) solid 1px;
    border-radius: 20px;
    overflow: scroll;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    box-shadow: -2px 1px 24px 2px rgba(255, 255, 255, 0.25);
}
.projectContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
}

.assetsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

::-webkit-scrollbar {
    display: none;
}
.sliderWrapper {
    display: flex;
    width: 100%;
}

/* IMG / VIDEO */
/* .projectVideoWrapper {
    max-width: 600px;
} */
.projectVideoWrapper img {
    max-width: 100%;
    max-height: 400px;
    margin-top: 50px;
    margin-bottom: 3%;
    border-radius: 5px;
}

.projectContainer img {
}
/* INFO */

.projectInfoWrapper {
    /* max-width: 100%; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
}

.projectInfo {
    max-width: 80%;
}

/* ======= TECHSTACK ========== */

.techStack {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 80%;
    margin: 10px;
}
.techStackIconWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.techStack > * {
    margin: 5px 10px 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-content: center;
}

.techStack img {
    margin-bottom: 10px;
    max-width: 25px;
}
.techStack img:hover {
    filter: drop-shadow(0 0 3px #ffffff);
}

.background {
    z-index: 4;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.487);
    /* border: solid; */
}

.resumeWrapper {
    /* position: absolute; */
    z-index: 5;
    /* FLEX  */
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    align-items: flex-end;
    align-content: center;
    justify-content: baseline;
    /*  */
    top: 15px;
    left: 50%;
    /* transform: translateX(-50%); */
}

#resumeImg {
    max-height: 95vh;
    max-width: 95vw;
    border-radius: 10px;
}

.downloadIcon {
    /* border: solid red 1px; */
    width: 40px;
    transition: 0.5s;
}
.downloadIcon a {
}

.downloadIcon:hover {
    width: 50px;
    transition: 0.5s;
}

@media only screen and (max-width: 470px) {
    .downloadIcon {
        display: none;
    }
}

.aboutPopup {
    position: absolute;
    /* display: flex; */
    padding: 20px;
    z-index: 4;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 1000px;
    width: 92%;
    max-height: 90%;
    /* min-height: 90%; */
    border: rgba(255, 255, 255, 0.763) solid 1px;
    border-radius: 20px;
    -webkit-backdrop-filter: blur(5px);
    background-color: #ffffff18;
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
    padding: -10%;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: scroll;
    box-shadow: -2px 1px 24px 2px rgba(255, 255, 255, 0.25);
}

.aboutWrapper {
    display: flex;
    top: 50%;
    padding: 20px;
    top: 10px;
    flex-direction: column;
    align-items: center;
}

.aboutWrapper p {
    /* width: 90%; */
    text-align: center;
}

.skillsWrapper {
    /* position: fixed; */
    top: 50%;
    padding: 20px;
    top: 10px;
}
.skillsHeading {
    position: relative;
}

#trenner {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    /* width: 90%; */
    /* border: 2px solid white; */
    background-color: white;
}

#aboutText {
    padding-top: 15px;
    width: 80%;
    text-align: -webkit-auto;
    line-break: auto;
    line-height: 32px;
    font-weight: bolder;
}
/*  ======= ICONS ================ */
.skillIconWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    top: 2em;
    width: 90%;
    min-width: 330px;
    grid-gap: 10px;
    gap: 10px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 40px;
    transition: 1s;
    padding: 5px;
    /* background-color: #ffffff12; */
    margin-bottom: 30px;
    /* border: solid; */
}

.skillIconWrapper > * {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 5px;
    padding: 10px;
    align-items: center;
    justify-content: flex-end;
    border: solid 1px;
    border-radius: 5px;
    width: 130px;
    height: 130px;
    background-color: rgba(0, 0, 0, 0.4);
}

.skillIconWrapper img {
    margin-bottom: 10px;
    max-width: 50px;
    min-width: 30px;
}
.skillIconWrapper p {
    font-weight: bold;
    color: white;
}
.skillIconWrapper img {
    filter: drop-shadow(0 0 2px #ffffff);
}
.skillIconWrapper img:hover {
    filter: drop-shadow(0 0 2px #ffffff);
    margin-bottom: 15px;
    transition: 0.3s;
}

#closeProjects {
    position: absolute;
    z-index: 35px;
    width: 35px;
    top: 12px;
    right: 15px;
    cursor: pointer;
    z-index: 5;
    /* border: solid white 1px; */

    /* background-color: white; */
    /* border: #ffffff solid 1px; */
    /* border-radius: 50%; */
}
#closeProjects:hover {
    filter: drop-shadow(0 0 2px #ffffff);
}

@font-face {
    font-family: "Morelife Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Morelife Regular"), url(/static/media/Morelife-JR0LM.7bae0b21.woff) format("woff");
}

* {
    /* box-sizing: border-box;
    margin: 0; */
}

#hero {
    display: flex;
    justify-content: center;
    align-items: center;
}

canvas {
    position: sticky;
    width: 100%;
    height: 100vh;
}
img {
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
}

/* a {
    position: absolute;
    bottom: 2vmin;
    right: 2vmin;
    color: rgba(255, 255, 255, 0.2);
    text-decoration: none;
} */

a:hover {
    color: #fff;
}

/*  ======  HEADER  ===
======== */
/* #header {
    border: solid 1px white;
    z-index: 5;
} */
/*/================ NAME ====================== */

h1 {
    font-family: "Morelife Regular";
    font-style: normal;
    font-weight: 400;
    font-style: normal;
    font-weight: normal;
    src: local("Morelife Regular"), url(/static/media/Morelife-JR0LM.7bae0b21.woff) format("woff");
    font-size: 5vmin; /* responsive font size */
    /* font-size: 50px; */
    font-size: calc(20px + 3.8vmin);
    text-align: center;
}

h2 {
    font-family: "Morelife Regular";
    font-style: normal;
    font-weight: 400;
    font-style: normal;
    font-weight: normal;
    src: local("Morelife Regular"), url(/static/media/Morelife-JR0LM.7bae0b21.woff) format("woff");
    font-size: 4vmin; /* responsive font size */
    /* font-size: 50px; */
    font-size: calc(20px + 3.8vmin);
    text-align: center;
}
h3 {
    /* font-size: 1.8vmin; responsive font size */
    font-size: calc(10px + 0.8vmin);
    text-align: center;
    font-size: 2.6vmin; /* responsive font size */
}
.infoName {
    position: absolute;
    top: 5%;
    left: 3%;
    z-index: 1;
    background-color: transparent;
}

.projectName {
    font-family: "Morelife Regular";
    font-style: normal;
    font-weight: 400;
    font-style: normal;
    font-weight: normal;
    src: local("Morelife Regular"), url(/static/media/Morelife-JR0LM.7bae0b21.woff) format("woff");
    font-size: 4vmin; /* responsive font size */
    /* font-size: 50px; */
    font-size: calc(20px + 3.8vmin);
    text-align: center;
}

/* SOCIALS Icon *****************************************/

.socialIcon:hover {
    transition: 0.5s;
    transform: rotate(-20deg);
    -webkit-transform: rotate(5deg);
    filter: drop-shadow(0 0 3px #ffffff);
    cursor: pointer;
    width: 200px;
}

#githubIcon {
    position: absolute;
    z-index: 1;
    transition: 0.5s;
    width: 34px;
    right: 80px;
    top: 5.4%;
}
#linkedinIcon {
    position: absolute;
    z-index: 1;
    transition: 0.5s;
    width: 42px;
    right: 30px;
    top: 5%;
}

#githubIcon:hover #linkedinIcon:hover {
    transform: rotate(-20deg);
    -webkit-transform: rotate(-10deg);
}

/* ********** PLANETS ************/

.planets {
    z-index: 2;
    display: flex;
    align-items: center;
    max-width: 90%;
    position: absolute;
    /* border: solid; */
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
}

.iconWrapper {
    display: flex;
    z-index: 1;
    transition: 1s;
    max-width: 200px;
    /* max-width: 220px; */
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    /* border: solid; */
}
/* .iconWrapper:hover {
    border: solid 1px;
} */
.planetIcon {
    max-width: 200px;
    width: 150px;
    transition: 1s;
    /* margin-left: 18px;
    margin-right: 18px; */
    /* border: white 1px solid; */
}

.planetIcon:hover {
    transition: 1s;
    transform: rotate(-20deg);
    -webkit-transform: rotate(5deg);
    filter: drop-shadow(0 0 3px #ffffff);
    cursor: pointer;
    /* border: solid 1px; */
}
@media only screen and (min-width: 400px) {
    .planetIcon:hover {
        width: 200px;
    }
}

.heading {
    text-align: center;
    opacity: 0;
    transition: all 500ms ease-in-out;
}

/* PROJECTS PLANET *****************************************/
#projectsPlanetWrapper {
}
#projectsPlanet {
    z-index: 1;
    transition: 0.5s;
}
#projectsPlanet:hover {
}

#projectsPlanet:hover + #projectsHeading {
    opacity: 1;
    visibility: visible;
}
/* RESUME PLANET *****************************************/
#resumePlanetWrapper {
}
#resumePlanet {
    z-index: 1;
    transition: 0.5s;
}
#resumePlanet:hover {
}

#resumePlanet:hover + #resumeHeading {
    opacity: 1;
    visibility: visible;
}
/* CONTACT PLANET  *****************************************/
#contactPlanetWrapper {
}

#contactPlanet {
    z-index: 1;
    transition: 0.5s;
}
#contactPlanet:hover {
}

#contactPlanet:hover + #contactHeading {
    opacity: 1;
    visibility: visible;
}

@media only screen and (max-width: 670px) {
    .planetIcon {
        max-width: 200px;
        width: calc(70px + 2.5vw);
        transition: 1s;
        /* margin-left: 18px;
    margin-right: 18px; */
        /* border: white 1px solid; */
    }

    #contactHeading {
        font-size: 15px;
        opacity: 1;
        display: none;
    }
    #skillsHeading {
        font-size: 15px;

        opacity: 1;
        display: none;
    }
    #resumeHeading {
        font-size: 15px;

        opacity: 1;
        display: none;
    }
    #resumeHeading {
        font-size: 15px;

        opacity: 1;
        display: none;
    }
    #projectsHeading {
        font-size: 15px;
        opacity: 1;
        display: none;
    }
}
/*  Skills Planet *****************************************/
#skillsPlanet {
    z-index: 1;
    transition: 0.5s;
    left: 65%;
    top: 70%;
}

#skillsPlanet:hover {
}

#skillsPlanet:hover + #skillsHeading {
    opacity: 1;
    visibility: visible;
}
/* Mercury */

#mercury {
    z-index: 1;
    transition: 0.5s;
    left: 10%;
    top: 20%;
}

