.aboutPopup {
    position: absolute;
    /* display: flex; */
    padding: 20px;
    z-index: 4;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 1000px;
    width: 92%;
    max-height: 90%;
    /* min-height: 90%; */
    border: rgba(255, 255, 255, 0.763) solid 1px;
    border-radius: 20px;
    -webkit-backdrop-filter: blur(5px);
    background-color: #ffffff18;
    backdrop-filter: blur(15px);
    padding: -10%;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: scroll;
    box-shadow: -2px 1px 24px 2px rgba(255, 255, 255, 0.25);
}

.aboutWrapper {
    display: flex;
    top: 50%;
    padding: 20px;
    top: 10px;
    flex-direction: column;
    align-items: center;
}

.aboutWrapper p {
    /* width: 90%; */
    text-align: center;
}

.skillsWrapper {
    /* position: fixed; */
    top: 50%;
    padding: 20px;
    top: 10px;
}
.skillsHeading {
    position: relative;
}

#trenner {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    /* width: 90%; */
    /* border: 2px solid white; */
    background-color: white;
}

#aboutText {
    padding-top: 15px;
    width: 80%;
    text-align: -webkit-auto;
    line-break: auto;
    line-height: 32px;
    font-weight: bolder;
}
/*  ======= ICONS ================ */
.skillIconWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    top: 2em;
    width: 90%;
    min-width: 330px;
    gap: 10px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 40px;
    transition: 1s;
    padding: 5px;
    /* background-color: #ffffff12; */
    margin-bottom: 30px;
    /* border: solid; */
}

.skillIconWrapper > * {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 5px;
    padding: 10px;
    align-items: center;
    justify-content: flex-end;
    border: solid 1px;
    border-radius: 5px;
    width: 130px;
    height: 130px;
    background-color: rgba(0, 0, 0, 0.4);
}

.skillIconWrapper img {
    margin-bottom: 10px;
    max-width: 50px;
    min-width: 30px;
}
.skillIconWrapper p {
    font-weight: bold;
    color: white;
}
.skillIconWrapper img {
    filter: drop-shadow(0 0 2px #ffffff);
}
.skillIconWrapper img:hover {
    filter: drop-shadow(0 0 2px #ffffff);
    margin-bottom: 15px;
    transition: 0.3s;
}

#closeProjects {
    position: absolute;
    z-index: 35px;
    width: 35px;
    top: 12px;
    right: 15px;
    cursor: pointer;
    z-index: 5;
    /* border: solid white 1px; */

    /* background-color: white; */
    /* border: #ffffff solid 1px; */
    /* border-radius: 50%; */
}
#closeProjects:hover {
    filter: drop-shadow(0 0 2px #ffffff);
}
