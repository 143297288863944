.contactWrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 4;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    padding: -10%;
    border: rgba(255, 255, 255, 0.763) solid 1px;
    border-radius: 20px;
    overflow: scroll;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    padding: 5%;
    box-shadow: -2px 1px 24px 2px rgba(255, 255, 255, 0.25);
}

.contactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5%;
    width: 90%;
    gap: 10px;
}

.contactForm input {
    width: 80%;
    border-radius: 5px;
    color: white;
    padding: 10px;
    background: none;
}

.contactForm textarea {
    width: 80%;
    height: 150px;
    color: white;
    border-radius: 5px;
    padding: 10px;
    background: none;
}

#sendButton {
    width: 60px;
    height: 60px;
    background-color: none;
    border-radius: 50%;
    /* background-color: white; */
    color: white;
    font-weight: bolder;
    font-size: 10px;
}

#sendButton:hover {
    cursor: pointer;
}
