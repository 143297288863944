#closeProjects {
    position: absolute;
    z-index: 35px;
    width: 35px;
    top: 12px;
    right: 15px;
    cursor: pointer;
    z-index: 5;
    /* border: solid white 1px; */

    /* background-color: white; */
    /* border: #ffffff solid 1px; */
    /* border-radius: 50%; */
}
#closeProjects:hover {
    filter: drop-shadow(0 0 2px #ffffff);
}

.githubProjectWrapper {
    position: absolute;
    z-index: 5;
    top: 12px;
    right: 55px;
    height: 32px;
    width: 32px;
    cursor: pointer;
    /* border: solid white 1px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.githubProjectWrapper img {
    max-width: 100%;
    max-height: 100%;
    /* position: absolute;
    top: 22px;
    left: 18px;
    height: 35px;
    width: 35px;
    padding: 2px;
    border: solid white 1px; */
}

.githubProjectWrapper:hover {
    filter: drop-shadow(0 0 2px #ffffff);
}
.githubProjectWrapper a {
}

/* ========================================== */
.projectsWrapper {
    position: absolute;
    z-index: 4;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 1000px;
    max-height: 90vh;
    padding: -10%;
    border: rgba(255, 255, 255, 0.763) solid 1px;
    border-radius: 20px;
    overflow: scroll;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    box-shadow: -2px 1px 24px 2px rgba(255, 255, 255, 0.25);
}
.projectContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
}

.assetsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

::-webkit-scrollbar {
    display: none;
}
.sliderWrapper {
    display: flex;
    width: 100%;
}

/* IMG / VIDEO */
/* .projectVideoWrapper {
    max-width: 600px;
} */
.projectVideoWrapper img {
    max-width: 100%;
    max-height: 400px;
    margin-top: 50px;
    margin-bottom: 3%;
    border-radius: 5px;
}

.projectContainer img {
}
/* INFO */

.projectInfoWrapper {
    /* max-width: 100%; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
}

.projectInfo {
    max-width: 80%;
}

/* ======= TECHSTACK ========== */

.techStack {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 80%;
    margin: 10px;
}
.techStackIconWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.techStack > * {
    margin: 5px 10px 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-content: center;
}

.techStack img {
    margin-bottom: 10px;
    max-width: 25px;
}
.techStack img:hover {
    filter: drop-shadow(0 0 3px #ffffff);
}
