/* ********** PLANETS ************/

.planets {
    z-index: 2;
    display: flex;
    align-items: center;
    max-width: 90%;
    position: absolute;
    /* border: solid; */
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
}

.iconWrapper {
    display: flex;
    z-index: 1;
    transition: 1s;
    max-width: 200px;
    /* max-width: 220px; */
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    /* border: solid; */
}
/* .iconWrapper:hover {
    border: solid 1px;
} */
.planetIcon {
    max-width: 200px;
    width: 150px;
    transition: 1s;
    /* margin-left: 18px;
    margin-right: 18px; */
    /* border: white 1px solid; */
}

.planetIcon:hover {
    transition: 1s;
    transform: rotate(-20deg);
    -webkit-transform: rotate(5deg);
    filter: drop-shadow(0 0 3px #ffffff);
    cursor: pointer;
    /* border: solid 1px; */
}
@media only screen and (min-width: 400px) {
    .planetIcon:hover {
        width: 200px;
    }
}

.heading {
    text-align: center;
    opacity: 0;
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -ms-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
}

/* PROJECTS PLANET *****************************************/
#projectsPlanetWrapper {
}
#projectsPlanet {
    z-index: 1;
    transition: 0.5s;
}
#projectsPlanet:hover {
}

#projectsPlanet:hover + #projectsHeading {
    opacity: 1;
    visibility: visible;
}
/* RESUME PLANET *****************************************/
#resumePlanetWrapper {
}
#resumePlanet {
    z-index: 1;
    transition: 0.5s;
}
#resumePlanet:hover {
}

#resumePlanet:hover + #resumeHeading {
    opacity: 1;
    visibility: visible;
}
/* CONTACT PLANET  *****************************************/
#contactPlanetWrapper {
}

#contactPlanet {
    z-index: 1;
    transition: 0.5s;
}
#contactPlanet:hover {
}

#contactPlanet:hover + #contactHeading {
    opacity: 1;
    visibility: visible;
}

@media only screen and (max-width: 670px) {
    .planetIcon {
        max-width: 200px;
        width: calc(70px + 2.5vw);
        transition: 1s;
        /* margin-left: 18px;
    margin-right: 18px; */
        /* border: white 1px solid; */
    }

    #contactHeading {
        font-size: 15px;
        opacity: 1;
        display: none;
    }
    #skillsHeading {
        font-size: 15px;

        opacity: 1;
        display: none;
    }
    #resumeHeading {
        font-size: 15px;

        opacity: 1;
        display: none;
    }
    #resumeHeading {
        font-size: 15px;

        opacity: 1;
        display: none;
    }
    #projectsHeading {
        font-size: 15px;
        opacity: 1;
        display: none;
    }
}
/*  Skills Planet *****************************************/
#skillsPlanet {
    z-index: 1;
    transition: 0.5s;
    left: 65%;
    top: 70%;
}

#skillsPlanet:hover {
}

#skillsPlanet:hover + #skillsHeading {
    opacity: 1;
    visibility: visible;
}
/* Mercury */

#mercury {
    z-index: 1;
    transition: 0.5s;
    left: 10%;
    top: 20%;
}
