* {
    box-sizing: border-box;
    color: white;
    margin: 0;
    font-family: "Montserrat", sans-serif;

    /* background-color: #0d0f33; */

    /* color: #5daf4e; */
}
.mainPage {
    /* width: 100vw; */
}

.pageWrapper {
}

.pageSection {
    margin: 0%;
    /* margin-top: 20px; */
    /* background-color: #0d0f33; */
    background-color: #000;
    /* background-image: radial-gradient(
            circle at top right,
            rgba(121, 68, 154, 0.13),
            transparent
        ),
        radial-gradient(
            circle at 20% 80%,
            rgba(41, 196, 255, 0.13),
            transparent
        ); */
}
